import Swiper from 'swiper'
import { Autoplay, Grid, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper/modules'
import { media } from './utils'

declare global {
  interface Window {
    Swiper: typeof Swiper
  }
}

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs])
Swiper.defaults.touchStartPreventDefault = false
window.Swiper = Swiper

export const createExampleSlider = (): void => {
  const slider = document.querySelector('*[data-slider="example"]') as HTMLDivElement

  if (!slider) return

  const value: string = String(slider.dataset.slider)
  const swiper = slider.querySelector(`*[data-slider-swiper="${value}"]`) as HTMLDivElement
  const pagination = slider.querySelector(`*[data-slider-pagination="${value}"]`) as HTMLDivElement
  const prev = slider.querySelector(`*[data-slider-prev="${value}"]`) as HTMLButtonElement
  const next = slider.querySelector(`*[data-slider-next="${value}"]`) as HTMLButtonElement

  new window.Swiper(swiper, {
    pagination: {
      el: pagination,
      clickable: true,
    },
    navigation: {
      prevEl: prev,
      nextEl: next,
    },
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 16,
    grabCursor: true,
    watchSlidesProgress: true,
    loop: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      [media.md]: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      [media.lg]: {
        slidesPerView: 1.3,
        spaceBetween: 28,
      },
      [media.xl]: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
    },
  }) as Swiper
}

export default (): void => {
  createExampleSlider()
}

import { scrolledPage } from './utils'

export default (): void => {
  const nav = document.querySelector('*[data-nav]') as HTMLElement

  if (!nav) return

  const header = document.querySelector('*[data-header]') as HTMLElement

  const scrollNav = (): void => {
    const currentOffsetTop: number = scrolledPage().top

    header.offsetHeight < currentOffsetTop ? nav.classList.add('bg-white') : nav.classList.remove('bg-white')
  }

  scrollNav()
  document.addEventListener('scroll', scrollNav as EventListener)
}
